<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.rejectBook.title') }}
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="desc" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.rejectBook.title') }}
        </div>
        <div class="date--time" v-if="modalData">
          <div class="single--row">
            <div class="left--col">{{ $t('general.rentDate') }}</div>
            <div class="right--col">{{ modalData.moveDate }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.filter.duration') }}</div>
            <div class="right--col">{{ modalData.duration }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.price') }}</div>
            <div class="right--col">{{ modalData.finalPrice }}</div>
          </div>
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-primary main--btn" @click="reject">
            {{ $t('booking.modal.rejectBook.acceptBtn') }}
          </div>
          <!--          <div class="btn btn-primary main&#45;&#45;btn" @click="close">-->
          <!--            Terima Permintaan Survei-->
          <!--          </div>-->
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'booking-request-reject-modal',
  mixins: [ModalMixin],
  components: {
    SectionLoader,
  },
  data() {
    return {
      modalName: 'booking-request-reject-modal',
      modalData: null,
      actionType: 'BOOKING_REQUEST',
      loading: false,
    };
  },
  methods: {
    beforeOpen(event) {
      this._closed();
      this._applicationUuid = event.params.bookingRequestHeaderUuid;
      this._fetchApplicationData();
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async reject() {
      this.loading = true;
      try {
        const { data } = await this.$http.post('api/v2/booking_request/reject', {
          bookingRequestHeaderUuid: this._applicationUuid,
        });
        if (data.data) {
          this.$emit('actionDone');
          this._close();
          this.$swal(
            this.$t('general.success'),
            this.$t('booking.swal.requestBookModal.rejectedMsg'),
            'success',
          );
        }
        // else {
        //     this.$swal('Success',
        //         'Tour gagal diterima',
        //         'error')
        // }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
